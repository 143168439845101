import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import RelatedProblems from "./components/RelatedProblems";
import EditMathProblem from "./components/EditMathProblem";
import pdfMake from "pdfmake/build/pdfmake";
import PrintingLoader from "./components/PrintingLoader";
import { RadioGroup, RadioGroupItem } from "./components/ui/radio-group";
import "katex/dist/katex.min.css";
import { InlineMath, BlockMath } from "react-katex";
import MathRenderer from "./components/MathRenderer";
import jsPDF from "jspdf";
import { svg2pdf } from "svg2pdf.js";
import "katex/dist/katex.min.css";
import katex from "katex";
import { toPng } from "html-to-image";
import { useIpAddress } from "./hooks/useIpAddress";
import { removeDiacritics } from "./lib/utils";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
} from "./components/ui/dialog";
import pdfFonts from "pdfmake/build/vfs_fonts";
import AssignedTasks from "./components/AssignedTasks";
import MixedContent from "./components/MixedContent";
import html2canvas from "html2canvas";
import TagManager from "./components/TagManager";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./components/ui/alert-dialog";
import { Card, CardContent } from "./components/ui/card";
import { Input } from "./components/ui/input";
import LessonCalendar from "./components/LessonCalendar";
import ReactConfetti from "react-confetti";
import AssignTask from "./components/AssignTask";
import Fuse from "fuse.js";
import { Button } from "./components/ui/button";
import { Label } from "./components/ui/label";
import {
  CheckCircle,
  Tag,
  Plus,
  BarChart2,
  ClipboardList,
  Printer,
  XCircle,
  ChevronLeft,
  Menu,
  User,
  Calendar,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import Masonry from "react-masonry-css";
import { supabase } from "./components/supabaseClient";
import { Auth } from "./components/Auth";
import UserProgress from "./components/UserProgress";
import ImprovedFiltering from "./components/ImprovedFiltering";
import Statistics from "./components/Statistics";
import MathProblemCard from "./components/MathProblemCard";
import ReactDOM from "react-dom";
import VideoRequest from "./components/VideoRequest";
import VideoRating from "./components/VideoRating";
import { difficultyStyles } from "./lib/mathProblemUtils";
import AddMathProblem from "./components/AddMathProblem";
import { toast, Toaster } from "react-hot-toast";
import UserProblemStatus from "./components/UserProblemStatus";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetClose,
} from "./components/ui/sheet";

const VariantSkeleton = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
    className="mt-6"
  >
    <div className="flex-1 space-y-4 py-1"></div>
  </motion.div>
);

const App = () => {
  const [session, setSession] = useState(null);
  const customFonts = {
    Roboto: {
      normal:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
      bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
      italics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
      bolditalics:
        "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
    },
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.fonts = customFonts;

  const [loading, setLoading] = useState(true);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [savingProblems, setSavingProblems] = useState({});
  const [mathProblems, setMathProblems] = useState([]);
  const [filteredProblems, setFilteredProblems] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState("všetky");
  const [searchTerm, setSearchTerm] = useState("");
  const [userAnswer, setUserAnswer] = useState("");
  const [showHint, setShowHint] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showVariants, setShowVariants] = useState(false);
  const [showAssignTask, setShowAssignTask] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedProblem, setSelectedProblem] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { problemId: urlProblemId } = useParams();

  const [selectedProblems, setSelectedProblems] = useState([]);
  const [currentVariants, setCurrentVariants] = useState([]);
  const [showAssignProblem, setShowAssignProblem] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showAssignedTasks, setShowAssignedTasks] = useState(false);
  const [streak, setStreak] = useState(0);
  const [showAddProblem, setShowAddProblem] = useState(false);
  const [xp, setXp] = useState(0);
  const [showEditProblem, setShowEditProblem] = useState(false);
  const [problemToEdit, setProblemToEdit] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [problemToDelete, setProblemToDelete] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [pdfHeadline, setPdfHeadline] = useState("");
  const [assignedTasksCount, setAssignedTasksCount] = useState(0);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [relatedProblems, setRelatedProblems] = useState([]);

  const [savedProblems, setSavedProblems] = useState([]);
  const [showSavedOnly, setShowSavedOnly] = useState(false);
  const [sortBy, setSortBy] = useState("newest");
  const [wantLogIn, setWantLogIn] = useState(false);
  const [showUserProblemStatus, setShowUserProblemStatus] = useState(false);
  const [showLevelConfetti, setShowLevelConfetti] = useState(false);
  const [showProblemConfetti, setShowProblemConfetti] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isVariantsLoading, setIsVariantsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [showStatistics, setShowStatistics] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAssigning, setIsAssigning] = useState(false);
  const [assignmentStudent, setAssignmentStudent] = useState(null);
  const [assignmentDueDate, setAssignmentDueDate] = useState(null);
  const [isTagManagerOpen, setIsTagManagerOpen] = useState(false);
  const [hasLoadedUserProgress, setHasLoadedUserProgress] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);

  const ipAddress = useIpAddress();

  const handleViewProblem = useCallback(
    (problemId) => {
      const problem = mathProblems.find((p) => p.id === problemId);
      if (problem) {
        setSelectedProblem(problem);
        setShowAssignedTasks(false);
        setShowStatistics(false); // Add this line
      }
    },
    [mathProblems]
  );

  const [filterCriteria, setFilterCriteria] = useState({
    searchTerm: "",
    selectedDifficulty: "všetky",
    selectedTags: [],
    sortBy: "newest",
    sortOrder: "asc",
    showSavedOnly: false,
  });

  const updateFilterCriteria = useCallback((newCriteria) => {
    setFilterCriteria((prev) => {
      const updated = {
        ...prev,
        ...newCriteria,
        selectedTags: Array.isArray(newCriteria.selectedTags)
          ? newCriteria.selectedTags
          : prev.selectedTags,
      };

      return updated;
    });
  }, []);

  useEffect(() => {
    setFilteredProblems(searchResults);
  }, [searchResults]);

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.Hub.Config({
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          processEscapes: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const { data, error } = await supabase
        .from("tags")
        .select("*")
        .order("name");

      if (error) throw error;
      setAllTags(data);
    } catch (error) {
      console.error("Error fetching tags:", error);
      toast.error("Failed to fetch tags");
    }
  };

  const getRelatedProblems = (currentProblem) => {
    if (!currentProblem) return [];

    return mathProblems
      .filter(
        (problem) =>
          problem.difficulty === currentProblem.difficulty &&
          problem.id !== currentProblem.id &&
          (problem.tags || []).some((tag) =>
            (currentProblem.tags || []).includes(tag)
          )
      )
      .slice(0, 2);
  };

  const toggleTagManager = () => {
    setIsTagManagerOpen(!isTagManagerOpen);
  };

  const handleSelectAll = useCallback(
    (checked) => {
      setAllSelected(checked);
      if (checked) {
        setSelectedProblems(filteredProblems.map((problem) => problem.id));
      } else {
        setSelectedProblems([]);
      }
    },
    [filteredProblems]
  );

  const handleStudentAndDateSelected = (studentId, dueDate) => {
    setIsAssigning(true);
    setAssignmentStudent(studentId);
    setAssignmentDueDate(dueDate);
    setShowAssignTask(false);
  };

  const handleAssignProblems = async () => {
    if (selectedProblems.length === 0) {
      toast.error("Prosím, vyberte aspoň jeden príklad");
      return;
    }

    const assignments = selectedProblems.map((problemId) => ({
      student_id: assignmentStudent,
      problem_id: problemId,
      due_date: assignmentDueDate,
    }));

    const { data, error } = await supabase
      .from("assigned_problems")
      .insert(assignments);

    if (error) {
      console.error("Chyba pri priraďovaní úloh:", error);
      toast.error("Nepodarilo sa priradiť úlohy");
    } else {
      toast.success("Úlohy boli úspešne priradené");
      setIsAssigning(false);
      setAssignmentStudent(null);
      setAssignmentDueDate(null);
      setSelectedProblems([]);
    }
  };

  const isAdminUser = session?.user?.email === "admin@matikaodpatrika.sk";

  const fuse = useMemo(
    () =>
      new Fuse(mathProblems, {
        keys: ["question", "topic"],
        threshold: 0.3,
        ignoreLocation: true,
        normalizeWhitespace: true,
      }),
    [mathProblems]
  );

  const fetchMathProblems = async () => {
    try {
      setIsInitialLoading(true);
      const { data, error } = await supabase.from("math_problems").select(`
        *,
        math_problem_tags (
          tag_id
        ),
        sub_questions
      `);

      if (error) throw error;

      const problemsWithTags = data.map((problem) => ({
        ...problem,
        tags: problem.math_problem_tags.map((mpt) => mpt.tag_id),
        sub_questions: problem.sub_questions || [],
      }));

      setMathProblems(problemsWithTags);
      setFilteredProblems(problemsWithTags);

      // Check if there's a problem ID in the URL
      const pathname = location.pathname;
      const problemIdMatch = pathname.match(/\/problem=(\d+)/);
      if (problemIdMatch) {
        const problemId = problemIdMatch[1];
        const problem = problemsWithTags.find(
          (p) => p.id.toString() === problemId
        );
        if (problem) {
          setSelectedProblem(problem);
        }
      }
    } catch (error) {
      console.error("Error fetching math problems:", error);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchMathProblems();
  }, []);

  const handleLogin = () => {
    setWantLogIn(true);
    navigate("/login");
  };

  const fetchAssignedTasksCount = async () => {
    if (!session) return;

    try {
      const { count, error } = await supabase
        .from("assigned_problems")
        .select("*", { count: "exact", head: true })
        .eq("student_id", session.user.id)
        .eq("status", "pending");

      if (error) throw error;
      setAssignedTasksCount(count || 0);
    } catch (error) {
      console.error("Error fetching assigned tasks count:", error);
    }
  };

  const handleProblemSelect = (problemId, isSelected) => {
    setShowAssignedTasks(false);
    setSelectedProblems((prev) =>
      isSelected ? [...prev, problemId] : prev.filter((id) => id !== problemId)
    );
  };
  const getImageSizeClass = (size) => {
    switch (size) {
      case "small":
        return "w-1/3";
      case "large":
        return "w-full";
      default:
        return "w-2/3";
    }
  };

  const getImageAlignmentClass = (alignment) => {
    switch (alignment) {
      case "left":
        return "float-left";
      case "right":
        return "float-right";
      default:
        return "mx-auto";
    }
  };

  const handlePrintProblems = async () => {
    const svgs = await Promise.all(
      selectedProblems.map(async (problemId) => {
        const problem = mathProblems.find((p) => p.id === problemId);
        if (!problem) return null;

        const container = document.createElement("div");
        container.style.padding = "20px";
        container.style.width = "800px";
        document.body.appendChild(container);

        ReactDOM.render(
          <div className="text-lg">
            <span className="font-bold mr-4">Príklad {problem.id}:</span>
            <MathRenderer math={problem.question} />
          </div>,
          container
        );

        await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for render

        const canvas = await html2canvas(container);
        const svg = new XMLSerializer().serializeToString(canvas);

        document.body.removeChild(container);
        return svg;
      })
    );
  };

  const handlePrintPDF = useCallback(async () => {
    if (!pdfHeadline.trim()) {
      toast.error("Please enter a headline for the PDF");
      return;
    }

    setIsPrinting(true);

    try {
      const pdf = new jsPDF();
      let yOffset = 20;

      // Add headline to the first page only
      pdf.setFontSize(18);
      pdf.text(pdfHeadline, pdf.internal.pageSize.getWidth() / 2, yOffset, {
        align: "center",
      });
      yOffset += 20;

      for (let i = 0; i < selectedProblems.length; i++) {
        const problemId = selectedProblems[i];
        const problem = mathProblems.find((p) => p.id === problemId);

        if (!problem) continue;

        // Create a temporary div to render the problem
        const tempDiv = document.createElement("div");
        tempDiv.className = "bg-white p-3 rounded shadow";
        tempDiv.style.width = "600px";

        const problemTitle = document.createElement("h2");
        problemTitle.className = "text-base font-bold mb-2";
        problemTitle.textContent = `Príklad ${i + 1}`;
        tempDiv.appendChild(problemTitle);

        const problemContent = document.createElement("div");
        problemContent.className = "text-sm";
        problemContent.innerHTML = problem.question
          .split("$")
          .map((part, index) => {
            if (index % 2 === 0) {
              return part;
            } else {
              return katex.renderToString(part, { displayMode: false });
            }
          })
          .join("");
        tempDiv.appendChild(problemContent);

        // Add problem image if exists
        if (problem.image_url) {
          const imgContainer = document.createElement("div");
          imgContainer.className = "mt-2 mb-2";

          // Set image container width based on image_size
          switch (problem.image_size) {
            case "small":
              imgContainer.style.width = "33%";
              break;
            case "medium":
              imgContainer.style.width = "66%";
              break;
            case "large":
              imgContainer.style.width = "100%";
              break;
            default:
              imgContainer.style.width = "66%"; // Default to medium if not specified
          }

          // Set image alignment
          switch (problem.image_alignment) {
            case "left":
              imgContainer.style.float = "left";
              imgContainer.style.marginRight = "10px";
              break;
            case "right":
              imgContainer.style.float = "right";
              imgContainer.style.marginLeft = "10px";
              break;
            case "center":
            default:
              imgContainer.style.margin = "0 auto";
              break;
          }

          const img = document.createElement("img");
          img.src = problem.image_url;
          img.className = "max-w-full h-auto";
          imgContainer.appendChild(img);
          tempDiv.appendChild(imgContainer);

          // Clear float if not center aligned
          if (problem.image_alignment !== "center") {
            const clearDiv = document.createElement("div");
            clearDiv.style.clear = "both";
            tempDiv.appendChild(clearDiv);
          }
        }
        // Add subquestions if they exist
        if (problem.sub_questions && problem.sub_questions.length > 0) {
          const subQuestionsContainer = document.createElement("div");
          subQuestionsContainer.className = "mt-4";

          problem.sub_questions.forEach((subQ, index) => {
            const subQuestionDiv = document.createElement("div");
            subQuestionDiv.className = "mt-2";

            const subQuestionTitle = document.createElement("h3");
            subQuestionTitle.className = "text-sm font-semibold";
            subQuestionTitle.textContent = `${String.fromCharCode(
              97 + index
            )}) `;
            subQuestionDiv.appendChild(subQuestionTitle);

            const subQuestionContent = document.createElement("span");
            subQuestionContent.innerHTML = subQ.question
              .split("$")
              .map((part, index) => {
                if (index % 2 === 0) {
                  return part;
                } else {
                  return katex.renderToString(part, { displayMode: false });
                }
              })
              .join("");
            subQuestionDiv.appendChild(subQuestionContent);

            // Add options for subquestion if they exist
            if (subQ.options && Array.isArray(subQ.options)) {
              const optionsContainer = document.createElement("div");
              optionsContainer.className = "mt-2 ml-4";
              subQ.options.forEach((option, optionIndex) => {
                const optionElement = document.createElement("div");
                optionElement.className = "flex items-start space-x-1 mt-1";

                const optionLabel = document.createElement("span");
                optionLabel.className = "font-bold text-sm";
                optionLabel.textContent = `${String.fromCharCode(
                  65 + optionIndex
                )}. `;

                const optionContent = document.createElement("div");
                optionContent.className = "text-sm";
                optionContent.innerHTML = option
                  .split("$")
                  .map((part, idx) => {
                    if (idx % 2 === 0) {
                      return part;
                    } else {
                      return katex.renderToString(part, { displayMode: false });
                    }
                  })
                  .join("");

                optionElement.appendChild(optionLabel);
                optionElement.appendChild(optionContent);
                optionsContainer.appendChild(optionElement);
              });
              subQuestionDiv.appendChild(optionsContainer);
            }

            subQuestionsContainer.appendChild(subQuestionDiv);
          });

          tempDiv.appendChild(subQuestionsContainer);
        }

        // Add options if they exist
        if (problem.options && Array.isArray(problem.options)) {
          const optionsContainer = document.createElement("div");
          optionsContainer.className = "mt-2";
          problem.options.forEach((option, index) => {
            const optionElement = document.createElement("div");
            optionElement.className = "flex items-start space-x-1 mt-1";

            const optionLabel = document.createElement("span");
            optionLabel.className = "font-bold text-sm";
            optionLabel.textContent = `${String.fromCharCode(65 + index)}. `;

            const optionContent = document.createElement("div");
            optionContent.className = "text-sm";
            optionContent.innerHTML = option
              .split("$")
              .map((part, idx) => {
                if (idx % 2 === 0) {
                  return part;
                } else {
                  return katex.renderToString(part, { displayMode: false });
                }
              })
              .join("");

            optionElement.appendChild(optionLabel);
            optionElement.appendChild(optionContent);
            optionsContainer.appendChild(optionElement);
          });
          tempDiv.appendChild(optionsContainer);
        }

        document.body.appendChild(tempDiv);

        // Convert the div to an image
        const pngData = await toPng(tempDiv);

        // Remove the temporary div
        document.body.removeChild(tempDiv);

        // Add the image to the PDF
        const imgProps = pdf.getImageProperties(pngData);
        const pdfWidth = pdf.internal.pageSize.getWidth() - 40; // Margin of 20 on each side
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        if (yOffset + pdfHeight > pdf.internal.pageSize.getHeight() - 20) {
          pdf.addPage();
          yOffset = 20; // Reset yOffset for new page, but don't add headline
        }

        pdf.addImage(pngData, "PNG", 20, yOffset, pdfWidth, pdfHeight);
        yOffset += pdfHeight + 10;

        // Add a divider between problems
        if (i < selectedProblems.length - 1) {
          if (yOffset + 10 > pdf.internal.pageSize.getHeight() - 20) {
            pdf.addPage();
            yOffset = 20; // Reset yOffset for new page, but don't add headline
          } else {
            pdf.setDrawColor(200);
            pdf.line(
              20,
              yOffset,
              pdf.internal.pageSize.getWidth() - 20,
              yOffset
            );
            yOffset += 10;
          }
        }
      }

      pdf.save("matika_od_patrika_priklady.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Chyba pri generovaní PDF");
    } finally {
      setIsPrinting(false);
      setPdfHeadline(""); // Reset the headline after generating the PDF
    }
  }, [selectedProblems, mathProblems, pdfHeadline]);

  // Helper function to load images
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleSearch = useCallback(
    (term) => {
      if (!term.trim()) {
        setSearchResults([]);
        setFilteredProblems(mathProblems);
        return;
      }

      const normalizedTerm = removeDiacritics(term.toLowerCase());
      const words = normalizedTerm.split(/\s+/);

      const filtered = mathProblems.filter((problem) => {
        const normalizedQuestion = removeDiacritics(
          problem.question.toLowerCase()
        );
        return words.every((word) => normalizedQuestion.includes(word));
      });

      setSearchResults(filtered);
      setFilteredProblems(filtered);
    },
    [mathProblems]
  );

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, mathProblems]);

  const difficultyOptions = [
    { value: "všetky", label: "Všetky úrovne" },
    { value: "ľahké", label: "Ľahké" },
    { value: "stredné", label: "Stredné" },
    { value: "ťažké", label: "Ťažké" },
  ];

  const handleToggleStatistics = () => {
    setShowStatistics(!showStatistics);
    setSelectedProblem(null);
    setIsMobileMenuOpen(false);
  };

  const handleEditProblem = (problem) => {
    setProblemToEdit(problem);
    setIsEditDialogOpen(true);
  };

  const handleDeleteProblem = (problemId) => {
    setProblemToDelete(problemId);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteProblem = async () => {
    if (problemToDelete) {
      try {
        // First, delete all variants of the problem
        const { data: variants, error: variantsError } = await supabase
          .from("math_problems")
          .delete()
          .eq("main_problem_id", problemToDelete);

        if (variantsError) throw variantsError;

        // Delete associated assigned problems
        const { error: assignedProblemsError } = await supabase
          .from("assigned_problems")
          .delete()
          .eq("problem_id", problemToDelete);

        if (assignedProblemsError) throw assignedProblemsError;

        // Delete associated video requests
        const { error: videoRequestsError } = await supabase
          .from("video_requests")
          .delete()
          .eq("problem_id", problemToDelete);

        if (videoRequestsError) throw videoRequestsError;

        // Delete associated video ratings
        const { error: videoRatingsError } = await supabase
          .from("video_ratings")
          .delete()
          .eq("problem_id", problemToDelete);

        if (videoRatingsError) throw videoRatingsError;

        // Finally, delete the main math problem
        const { error: mathProblemError } = await supabase
          .from("math_problems")
          .delete()
          .eq("id", problemToDelete);

        if (mathProblemError) throw mathProblemError;

        setMathProblems(mathProblems.filter((p) => p.id !== problemToDelete));
        setFilteredProblems(
          filteredProblems.filter((p) => p.id !== problemToDelete)
        );
        toast.success("Príklad a jeho varianty boli úspešne vymazané");
      } catch (error) {
        console.error("Error deleting problem:", error.message);
        toast.error("Chyba pri mazaní príkladu: " + error.message);
      } finally {
        setShowDeleteConfirmation(false);
        setProblemToDelete(null);
      }
    }
  };

  const handleProblemUpdated = (updatedProblem) => {
    setMathProblems(
      mathProblems.map((p) => (p.id === updatedProblem.id ? updatedProblem : p))
    );
    setFilteredProblems(
      filteredProblems.map((p) =>
        p.id === updatedProblem.id ? updatedProblem : p
      )
    );
    setShowEditProblem(false);
    setProblemToEdit(null);
  };

  const sortOptions = [
    { value: "newest", label: "Najnovšie" },
    { value: "difficulty", label: "Obtiažnosť" },
    { value: "topic", label: "Téma" },
  ];

  const handleBackToList = useCallback(() => {
    setSelectedProblem(null);
    setUserAnswer("");
    setShowHint(false);
    setFeedback(null);
    setCurrentVariants([]);
    setShowVariants(false);
    setSearchTerm("");
    updateFilterCriteria({ searchTerm: "" });
    navigate("/"); // This will change the URL to the root path
  }, [updateFilterCriteria]);

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleDropdownClose = useCallback(() => {
    setIsDropdownOpen(false);
  }, []);

  useEffect(() => {
    setAllSelected(false);
    setSelectedProblems([]);
  }, [filteredProblems]);
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("click", handleDropdownClose);
    } else {
      document.removeEventListener("click", handleDropdownClose);
    }
    return () => {
      document.removeEventListener("click", handleDropdownClose);
    };
  }, [isDropdownOpen, handleDropdownClose]);

  const handleFilter = useCallback(() => {
    let filtered = mathProblems;

    if (searchTerm) {
      const results = fuse.search(searchTerm);
      filtered = results.map((result) => result.item);
    }

    filtered = filtered.filter((problem) => {
      const difficultyMatch =
        selectedDifficulty === "všetky" ||
        problem.difficulty === selectedDifficulty;
      const savedMatch = !showSavedOnly || savedProblems.includes(problem.id);

      // Ensure problem.tags exists and is an array
      const problemTags = Array.isArray(problem.tags) ? problem.tags : [];

      const tagMatch =
        selectedTags.length === 0 ||
        selectedTags.every((selectedTag) => {
          // Ensure selectedTag is an object with a name property
          const selectedTagName =
            selectedTag && selectedTag.name
              ? selectedTag.name.toLowerCase()
              : "";

          return problemTags.some((problemTag) => {
            // Handle both string and object problemTags
            const problemTagName =
              typeof problemTag === "string"
                ? problemTag.toLowerCase()
                : problemTag && problemTag.name
                ? problemTag.name.toLowerCase()
                : "";

            return problemTagName === selectedTagName;
          });
        });

      return difficultyMatch && savedMatch && tagMatch;
    });

    if (sortBy !== "default") {
      filtered.sort((a, b) => {
        if (a[sortBy] < b[sortBy]) return sortOrder === "asc" ? -1 : 1;
        if (a[sortBy] > b[sortBy]) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });
    }

    setFilteredProblems(filtered);
  }, [
    fuse,
    searchTerm,
    selectedDifficulty,
    showSavedOnly,
    savedProblems,
    selectedTags,
    sortBy,
    sortOrder,
    mathProblems,
  ]);

  useEffect(() => {
    handleFilter();
  }, [handleFilter]);

  const fetchUserProgress = useCallback(
    async (userId) => {
      if (hasLoadedUserProgress) return;
      setIsUserDataLoading(true);
      const { data, error } = await supabase
        .from("user_progress")
        .select("streak, xp, saved_problems")
        .eq("user_id", userId)
        .single();

      if (error) {
        console.error("Error fetching user progress:", error);
        if (error.code === "PGRST116") {
          await createUserProgress(userId);
        }
      } else if (data) {
        setStreak(data.streak);
        setXp(data.xp);
        setSavedProblems(data.saved_problems || []);
      } else {
        await createUserProgress(userId);
      }
      setHasLoadedUserProgress(true);
      setIsUserDataLoading(false);
    },
    [hasLoadedUserProgress]
  );

  useEffect(() => {
    if (session) {
      fetchUserProgress(session.user.id);
      fetchAssignedTasksCount();
    } else {
      setIsUserDataLoading(false);
    }
  }, [session, fetchUserProgress]);

  useEffect(() => {
    const fetchSession = async () => {
      setLoading(true);
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setSession(session);
      if (session && !hasLoadedUserProgress) {
        // Modify this line
        await fetchUserProgress(session.user.id);
      } else {
        setIsUserDataLoading(false);
      }
      setLoading(false);
    };

    fetchSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session && !hasLoadedUserProgress) {
        // Modify this line
        fetchUserProgress(session.user.id);
      } else {
        setIsUserDataLoading(false);
      }
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, [fetchUserProgress, hasLoadedUserProgress]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
    updateFilterCriteria({ searchTerm: value });
  };

  const createUserProgress = async (userId) => {
    const { data, error } = await supabase
      .from("user_progress")
      .insert({ user_id: userId, streak: 0, xp: 0, saved_problems: [] })
      .single();

    if (error) {
      console.error("Error creating user progress:", error);
    } else {
      setStreak(0);
      setXp(0);
      setSavedProblems([]);
    }
  };

  const updateUserProgress = async (
    userId,
    newStreak,
    newXp,
    newSavedProblems
  ) => {
    const { data, error } = await supabase
      .from("user_progress")
      .update({
        streak: newStreak,
        xp: newXp,
        saved_problems: newSavedProblems,
      })
      .eq("user_id", userId)
      .single();

    if (error) {
      console.error("Error updating user progress:", error);
    } else {
      setStreak(newStreak);
      setXp(newXp);
      setSavedProblems(newSavedProblems);
    }
  };

  const startChallenge = (problem) => {
    setSelectedProblem(problem);
    setUserAnswer("");
    setUserAnswers([]); // Reset userAnswers
    setShowHint(false);
    setFeedback(null);

    // Fetch variants if they exist
    if (problem.main_problem_id) {
      // This is a variant, fetch siblings and main problem
      fetchVariants(problem.main_problem_id, problem.id);
    } else {
      // This is a main problem, fetch its variants
      fetchVariants(problem.id);
    }
  };

  useEffect(() => {
    const pathname = location.pathname;
    const problemIdMatch = pathname.match(/\/problem=(\d+)/);

    if (problemIdMatch) {
      const problemId = problemIdMatch[1];
      const problem = mathProblems.find((p) => p.id.toString() === problemId);
      if (problem) {
        setSelectedProblem(problem);
      }
    } else {
      setSelectedProblem(null);
    }
  }, [location, mathProblems]);

  const handleProblemClick = (problem) => {
    navigate(`/problem=${problem.id}`);
    setSelectedProblem(problem);
  };

  const fetchVariants = async (mainProblemId, currentProblemId = null) => {
    setIsVariantsLoading(true);
    try {
      const { data, error } = await supabase
        .from("math_problems")
        .select("*")
        .or(`id.eq.${mainProblemId},main_problem_id.eq.${mainProblemId}`)
        .neq("id", currentProblemId || mainProblemId);

      if (error) throw error;
      setCurrentVariants(data);
    } catch (error) {
      console.error("Error fetching variants:", error);
      setCurrentVariants([]);
    } finally {
      setIsVariantsLoading(false);
    }
  };

  const checkSolution = async () => {
    if (!selectedProblem) {
      console.error("No problem selected");
      return;
    }

    const normalizeAnswer = (answer) => {
      return answer.replace(",", ".").trim().toLowerCase();
    };

    let isCorrect = false;
    let feedbackMessage = "";

    if (selectedProblem.options) {
      // For multiple choice questions
      isCorrect = selectedOption === selectedProblem.answer[0];
      feedbackMessage = isCorrect
        ? "Výborne! Tvoja odpoveď je správna."
        : "Prepáč, to nie je správna odpoveď. Skús to znova.";
    } else if (
      selectedProblem.sub_questions &&
      selectedProblem.sub_questions.length > 1
    ) {
      // For multiple sub-questions
      isCorrect = selectedProblem.sub_questions.every((subQ, index) => {
        const userNormalizedAnswer = normalizeAnswer(userAnswers[index] || "");
        return Array.isArray(subQ.answer)
          ? subQ.answer.some(
              (correctAnswer) =>
                normalizeAnswer(correctAnswer) === userNormalizedAnswer
            )
          : normalizeAnswer(subQ.answer) === userNormalizedAnswer;
      });
      feedbackMessage = isCorrect
        ? "Brilantné! Všetky tvoje odpovede sú správne!"
        : "Takmer! Niektoré odpovede nie sú správne. Skús to ešte raz.";
    } else {
      // For single answer questions
      const userNormalizedAnswer = normalizeAnswer(userAnswer);
      isCorrect = selectedProblem.answer.some(
        (correctAnswer) =>
          normalizeAnswer(correctAnswer) === userNormalizedAnswer
      );
      feedbackMessage = isCorrect
        ? "Brilantné! Tvoje matematické schopnosti sú pozoruhodné!"
        : "Takmer! Skús to ešte raz a nezabúdaj na detaily.";
    }

    setFeedback({
      correct: isCorrect,
      message: feedbackMessage,
    });

    // Only update user progress if logged in
    if (session && isCorrect) {
      const newStreak = streak + 1;
      const newXp = xp + 20;

      // Update the assigned problem status
      const { error } = await supabase
        .from("assigned_problems")
        .update({ status: "completed" })
        .match({
          student_id: session.user.id,
          problem_id: selectedProblem.id,
        });

      if (error) {
      } else {
        // Refresh the assigned tasks count
        fetchAssignedTasksCount();
      }

      await updateUserProgress(
        session.user.id,
        newStreak,
        newXp,
        savedProblems
      );
      setStreak(newStreak);
      setXp(newXp);
      handleProblemComplete();
      if (Math.floor(newXp / 100) > Math.floor(xp / 100)) {
        handleLevelUp();
      }
    } else if (session && !isCorrect) {
      await updateUserProgress(session.user.id, 0, xp, savedProblems);
      setStreak(0);
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setIsMobileMenuOpen(false);
    setWantLogIn(false);
  };

  const handleLevelUp = useCallback(() => {
    if (!isInitialLoad) {
      setShowLevelConfetti(true);
      setTimeout(() => setShowLevelConfetti(false), 5000);
    }
  }, [isInitialLoad]);

  const handleProblemComplete = useCallback(() => {
    if (!isInitialLoad) {
      setShowProblemConfetti(true);
      setTimeout(() => setShowProblemConfetti(false), 3000);
    }
  }, [isInitialLoad]);

  const canSaveProblems = useCallback(() => {
    return !!session;
  }, [session]);

  const handleSaveProblem = useCallback(
    async (problemId) => {
      if (!canSaveProblems()) {
        toast.error("Pre uloženie príkladu sa musíte prihlásiť.", {
          duration: 3000,
          icon: "🔒",
        });
        return;
      }

      setSavingProblems((prev) => ({ ...prev, [problemId]: true }));

      try {
        const newSavedProblems = savedProblems.includes(problemId)
          ? savedProblems.filter((id) => id !== problemId)
          : [...savedProblems, problemId];

        await updateUserProgress(session.user.id, streak, xp, newSavedProblems);
        setSavedProblems(newSavedProblems);

        // Add toast notification
        if (newSavedProblems.includes(problemId)) {
          toast.success("Príklad bol úspešne uložený", {
            duration: 3000,
            icon: "🔖",
          });
        } else {
          toast.success("Príklad bol odstránený z uložených", {
            duration: 3000,
            icon: "🗑️",
          });
        }
      } catch (error) {
        console.error("Error saving problem:", error);
        toast.error("Chyba pri ukladaní príkladu", {
          duration: 3000,
          icon: "❌",
        });
      } finally {
        setSavingProblems((prev) => ({ ...prev, [problemId]: false }));
      }
    },
    [session, savedProblems, streak, xp, updateUserProgress, canSaveProblems]
  );

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
  };

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  const handleAddProblem = () => {
    setShowAddProblem(true);
  };

  const handleProblemAdded = useCallback(
    (newProblem) => {
      setMathProblems((prevProblems) => [newProblem, ...prevProblems]);
      setFilteredProblems((prevFiltered) => [newProblem, ...prevFiltered]);
      fetchMathProblems(); // Refresh the problem list
      toast.success("Príklad bol úspešne pridaný");
    },
    [fetchMathProblems]
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (wantLogIn && !session) {
    return (
      <div
        className="flex justify-center items-center h-screen bg-gray-50"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='220' height='220' viewBox='0 0 220 220'%3E%3Cpath d='M0 20h220M0 40h220M0 60h220M0 80h220M0 100h220M0 120h220M0 140h220M0 160h220M0 180h220M0 200h220M20 0v220M40 0v220M60 0v220M80 0v220M100 0v220M120 0v220M140 0v220M160 0v220M180 0v220M200 0v220' stroke='%23E6E6E6' stroke-width='0.5' opacity='0.4'/%3E%3Cpath d='M0 0h220M0 220h220M0 0v220M220 0v220' stroke='%23D9D9D9' stroke-width='0.75' opacity='0.5'/%3E%3C/svg%3E")`,
          backgroundSize: "100px 100px",
        }}
      >
        <Auth />
      </div>
    );
  }

  return (
    <div
      className="min-h-screen bg-white p-4 md:p-8 md:pt-4"
      style={{
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='220' height='220' viewBox='0 0 220 220'%3E%3Cpath d='M0 20h220M0 40h220M0 60h220M0 80h220M0 100h220M0 120h220M0 140h220M0 160h220M0 180h220M0 200h220M20 0v220M40 0v220M60 0v220M80 0v220M100 0v220M120 0v220M140 0v220M160 0v220M180 0v220M200 0v220' stroke='%23E6E6E6' stroke-width='0.5' opacity='0.4'/%3E%3Cpath d='M0 0h220M0 220h220M0 0v220M220 0v220' stroke='%23D9D9D9' stroke-width='0.75' opacity='0.5'/%3E%3C/svg%3E")`,
        backgroundSize: "100px 100px",
      }}
    >
      <Toaster position="top-center" />
      {!isInitialLoad && showLevelConfetti && (
        <ReactConfetti
          recycle={false}
          numberOfPieces={200}
          tweenDuration={5000}
        />
      )}
      {!isInitialLoad && showProblemConfetti && (
        <ReactConfetti
          recycle={false}
          numberOfPieces={50}
          tweenDuration={3000}
          gravity={0.2}
        />
      )}

      {isAdminUser && selectedProblems.length > 0 && !isAssigning && (
        <Button
          onClick={() => setIsPrinting(true)}
          className="fixed bottom-4 right-4 z-50"
          disabled={isPrinting}
        >
          <Printer className="mr-2 h-4 w-4" />
          {isPrinting ? "Generujem PDF..." : "Vytlačiť Vybrané Príklady"}
        </Button>
      )}

      <nav className="mb-8">
        <div className="flex justify-between items-center">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => {
              setSelectedProblem(null);
              setShowStatistics(false);
              navigate("/"); // This will change the URL to the root path
            }}
          >
            <img
              src="/matpat.png"
              alt="Matika od Patrika Logo"
              className="max-w-full h-auto w-20 md:w-20"
            />
          </div>

          <div className="flex items-center space-x-4">
            {session && !isUserDataLoading && (
              <UserProgress streak={streak} xp={xp} onLevelUp={handleLevelUp} />
            )}
            {session && (
              <Button
                variant="ghost"
                size="sm"
                onClick={handleToggleStatistics}
                className="hidden md:flex items-center"
              >
                <BarChart2 className="h-5 w-5 mr-1" />
                <span>Štatistiky</span>
              </Button>
            )}

            {isAdminUser && (
              <>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowAddProblem(true)}
                  className="hidden md:flex items-center"
                >
                  <Plus className="h-5 w-5 mr-1" />
                  <span>Pridať príklad</span>
                </Button>

                <Button
                  variant="ghost"
                  size="sm"
                  onClick={toggleTagManager}
                  className="hidden md:flex items-center"
                >
                  <Tag className="h-5 w-5 mr-1" />
                  <span>Spravovať tagy</span>
                </Button>
              </>
            )}

            <Button
              variant="ghost"
              size="sm"
              onClick={toggleMobileMenu}
              className="md:hidden"
            >
              <Menu className="h-5 w-5" />
            </Button>

            <Button
              variant="ghost"
              className="hidden md:flex items-center"
              onClick={toggleCalendar}
            >
              Kalendár lekcií
            </Button>

            {session && !isAdminUser && (
              <div className="relative">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => setShowAssignedTasks(true)}
                  className="hidden md:flex items-center px-3 py-2 text-sm font-medium hover:bg-gray-100 hover:text-gray-900 rounded-md transition-colors duration-150 ease-in-out"
                >
                  Moje úlohy
                  {assignedTasksCount > 0 && (
                    <span className="ml-2 bg-red-100 text-red-800 text-xs font-semibold px-2 py-0.5 rounded-full">
                      {assignedTasksCount}
                    </span>
                  )}
                </Button>
              </div>
            )}

            {isAdminUser && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowAssignTask(true)}
                className="hidden md:flex items-center"
              >
                <Plus className="h-5 w-5 mr-1" />
                <span>Priradiť úlohu</span>
              </Button>
            )}

            {isAdminUser && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setShowUserProblemStatus(true)}
                className="hidden md:flex items-center"
              >
                <ClipboardList className="h-5 w-5 mr-1" />
                <span>Stav úloh používateľov</span>
              </Button>
            )}

            {session ? (
              <Button
                variant="ghost"
                size="sm"
                onClick={handleLogout}
                className="hidden md:flex items-center"
              >
                <User className="h-5 w-5 mr-1" />
                <span>Odhlásiť sa</span>
              </Button>
            ) : (
              <Button
                variant="ghost"
                size="sm"
                onClick={handleLogin}
                className="hidden md:flex items-center"
              >
                <User className="h-5 w-5 mr-1" />
                <span>Prihlásiť sa</span>
              </Button>
            )}
          </div>
        </div>
      </nav>

      <Dialog open={isTagManagerOpen} onOpenChange={setIsTagManagerOpen}>
        <DialogContent className="max-w-4xl max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Správa tagov</DialogTitle>
          </DialogHeader>
          <TagManager onClose={() => setIsTagManagerOpen(false)} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={showUserProblemStatus}
        onOpenChange={setShowUserProblemStatus}
      >
        <DialogContent className="max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
          <DialogHeader>
            <DialogTitle>Stav úloh používateľov</DialogTitle>
          </DialogHeader>
          <div className="flex-grow overflow-hidden">
            <UserProblemStatus />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={showAssignedTasks} onOpenChange={setShowAssignedTasks}>
        <DialogContent className="max-w-4xl max-h-[90vh] overflow-auto">
          <DialogHeader>
            <DialogTitle>Vaše zadané úlohy</DialogTitle>
            <DialogDescription>
              Tu nájdete zoznam všetkých vašich aktuálnych úloh.
            </DialogDescription>
          </DialogHeader>
          <AssignedTasks
            userId={session?.user?.id}
            onViewProblem={handleViewProblem}
            onTaskCompleted={() =>
              setAssignedTasksCount((prev) => Math.max(prev - 1, 0))
            }
            onClose={() => setShowAssignedTasks(false)}
          />
        </DialogContent>
      </Dialog>

      {/* Mobile Menu */}
      <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
        <SheetContent side="right">
          <SheetHeader>
            <SheetTitle>Menu</SheetTitle>
          </SheetHeader>
          <div className="py-4">
            {session && (
              <Button
                variant="ghost"
                className="w-full justify-start mb-2"
                onClick={() => {
                  handleToggleStatistics();
                  setIsMobileMenuOpen(false);
                }}
              >
                <BarChart2 className="mr-2 h-4 w-4" />
                {showStatistics ? "Príklady" : "Štatistiky"}
              </Button>
            )}
            {isAdminUser && (
              <Button
                variant="ghost"
                className="w-full justify-start mb-2"
                onClick={() => {
                  handleAddProblem();
                  setIsMobileMenuOpen(false);
                }}
              >
                <Plus className="mr-2 h-4 w-4" />
                Pridať príklad
              </Button>
            )}
            {session && !isAdminUser && (
              <Button
                variant="ghost"
                className="w-full justify-start mb-2"
                onClick={() => {
                  setShowAssignedTasks(true);
                  setIsMobileMenuOpen(false);
                }}
              >
                <ClipboardList className="mr-2 h-4 w-4" />
                Moje úlohy
                {assignedTasksCount > 0 && (
                  <span className="ml-2 bg-red-100 text-red-800 text-xs font-semibold px-2 py-0.5 rounded-full">
                    {assignedTasksCount}
                  </span>
                )}
              </Button>
            )}
            <Button
              variant="ghost"
              className="w-full justify-start mb-2"
              onClick={() => {
                toggleCalendar();
                setIsMobileMenuOpen(false);
              }}
            >
              <Calendar className="mr-2 h-4 w-4" />
              Kalendár lekcií
            </Button>
            {session ? (
              <Button
                variant="ghost"
                className="w-full justify-start"
                onClick={() => {
                  handleLogout();
                  setIsMobileMenuOpen(false);
                }}
              >
                <User className="mr-2 h-4 w-4" />
                Odhlásiť sa
              </Button>
            ) : (
              <Button
                variant="ghost"
                className="w-full justify-start"
                onClick={() => {
                  handleLogin();
                  setIsMobileMenuOpen(false);
                }}
              >
                <User className="mr-2 h-4 w-4" />
                Prihlásiť sa
              </Button>
            )}
          </div>
        </SheetContent>
      </Sheet>

      {/* Main Content */}

      {showAssignTask && (
        <Dialog open={showAssignTask} onOpenChange={setShowAssignTask}>
          <DialogContent>
            <AssignTask
              onClose={() => setShowAssignTask(false)}
              onStudentAndDateSelected={handleStudentAndDateSelected}
            />
          </DialogContent>
        </Dialog>
      )}

      {showStatistics && session ? (
        <div className="max-w-4xl mx-auto">
          <Button
            onClick={handleToggleStatistics}
            className="mb-4 flex items-center"
            variant="outline"
          >
            <ChevronLeft className="mr-2" /> Späť na príklady
          </Button>
          <Statistics userId={session?.user?.id} />
        </div>
      ) : (
        <>
          {!selectedProblem && (
            <div className="mb-14 max-w-2xl mx-auto">
              <ImprovedFiltering
                allSelected={allSelected}
                isAdminUser={isAdminUser}
                setSearchResults={setSearchResults}
                onSelectAll={handleSelectAll}
                onProblemClick={handleProblemClick}
                searchTerm={filterCriteria.searchTerm}
                setSearchTerm={(term) => {
                  setSearchTerm(term);
                  updateFilterCriteria({ searchTerm: term });
                }}
                selectedDifficulty={filterCriteria.selectedDifficulty}
                setSelectedDifficulty={(difficulty) =>
                  updateFilterCriteria({ selectedDifficulty: difficulty })
                }
                selectedTags={filterCriteria.selectedTags}
                setSelectedTags={(tags) =>
                  updateFilterCriteria({ selectedTags: tags })
                }
                sortBy={filterCriteria.sortBy}
                setSortBy={(sort) => updateFilterCriteria({ sortBy: sort })}
                sortOrder={filterCriteria.sortOrder}
                toggleSortOrder={() =>
                  updateFilterCriteria({
                    sortOrder:
                      filterCriteria.sortOrder === "asc" ? "desc" : "asc",
                  })
                }
                showSavedOnly={filterCriteria.showSavedOnly}
                setShowSavedOnly={(show) =>
                  updateFilterCriteria({ showSavedOnly: show })
                }
                difficultyOptions={difficultyOptions}
                allTags={allTags}
                sortOptions={sortOptions}
                canSaveProblems={canSaveProblems()}
                onSearch={handleSearch}
                searchResults={searchResults}
                onProblemSelect={handleProblemSelect}
                setFilteredProblems={setFilteredProblems}
                mathProblems={mathProblems}
                savedProblems={savedProblems}
                updateFilterCriteria={updateFilterCriteria}
              />
            </div>
          )}
          {isAssigning && (
            <Button
              className="fixed bottom-4 right-4 z-50"
              onClick={handleAssignProblems}
            >
              Priradiť študentovi
            </Button>
          )}
          {selectedProblem ? (
            <div className="flex flex-col space-y-4">
              <Button
                onClick={handleBackToList}
                className="mb-4 flex items-center self-start"
                variant="outline"
              >
                <ChevronLeft className="mr-2" /> Späť na zoznam príkladov
              </Button>

              <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
                <div className="w-full order-2 lg:order-1 lg:w-1/3">
                  <div className="hidden lg:block">
                    <RelatedProblems
                      problems={getRelatedProblems(selectedProblem)}
                      onProblemClick={(problem) => {
                        setSelectedProblem(problem);
                        navigate(`/problem=${problem.id}`);
                      }}
                    />
                  </div>
                </div>
                <div className="w-full order-1 lg:order-2 lg:w-2/3">
                  <Card className="p-6">
                    <h2 className="text-xl leading-loose text-gray-800 mb-4">
                      <MixedContent content={selectedProblem.question} />
                    </h2>

                    {selectedProblem.image_url && (
                      <div className="mb-4 w-full overflow-hidden">
                        <div
                          className={`${getImageSizeClass(
                            selectedProblem.image_size
                          )} ${getImageAlignmentClass(
                            selectedProblem.image_alignment
                          )}`}
                        >
                          <img
                            src={selectedProblem.image_url}
                            alt="Problem illustration"
                            className="w-full h-auto rounded-lg"
                          />
                        </div>
                      </div>
                    )}
                    {selectedProblem.image && (
                      <div className="mb-4">
                        <img
                          src={selectedProblem.image}
                          alt="Problem illustration"
                          className="w-full h-auto rounded-lg"
                        />
                      </div>
                    )}
                    <div className="mb-4">
                      {selectedProblem.video_tutorial_url ? (
                        <div>
                          <h3 className="text-lg font-semibold mb-2">
                            Video tutoriál
                          </h3>
                          <div
                            className="relative w-full"
                            style={{ paddingTop: "56.25%" }}
                          >
                            {" "}
                            {/* 16:9 Aspect Ratio */}
                            <iframe
                              className="absolute top-0 left-0 w-full h-full"
                              src={selectedProblem.video_tutorial_url}
                              title="Video tutoriál"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                            ></iframe>
                          </div>
                          <div className="mt-2">
                            <VideoRating problemId={selectedProblem.id} />
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    {selectedProblem.options ? (
                      <Card className="mt-4 p-4">
                        <RadioGroup
                          value={selectedOption}
                          onValueChange={setSelectedOption}
                          className="space-y-2"
                        >
                          {selectedProblem.options.map((option, index) => (
                            <div
                              key={index}
                              className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 transition-colors"
                            >
                              <RadioGroupItem
                                value={option}
                                id={`option-${index}`}
                                className="w-4 h-4"
                              />
                              <Label
                                htmlFor={`option-${index}`}
                                className="flex-grow cursor-pointer text-lg"
                              >
                                <MixedContent content={option} />
                              </Label>
                            </div>
                          ))}
                        </RadioGroup>
                      </Card>
                    ) : selectedProblem.sub_questions &&
                      selectedProblem.sub_questions.length > 1 ? (
                      <div className="space-y-4 mt-4">
                        {selectedProblem.sub_questions.map((subQ, index) => (
                          <div key={index} className="space-y-2">
                            <Label
                              htmlFor={`answer-${index}`}
                              className="text-lg"
                            >
                              <MixedContent content={subQ.question} />
                            </Label>
                            {subQ.type === "select" ? (
                              <RadioGroup
                                value={userAnswers[index] || ""}
                                onValueChange={(value) => {
                                  const newAnswers = [...userAnswers];
                                  newAnswers[index] = value;
                                  setUserAnswers(newAnswers);
                                }}
                              >
                                {subQ.options.map((option, optionIndex) => (
                                  <div
                                    key={optionIndex}
                                    className="flex items-center space-x-2"
                                  >
                                    <RadioGroupItem
                                      value={option}
                                      id={`option-${index}-${optionIndex}`}
                                    />
                                    <Label
                                      htmlFor={`option-${index}-${optionIndex}`}
                                    >
                                      <MixedContent content={option} />
                                    </Label>
                                  </div>
                                ))}
                              </RadioGroup>
                            ) : (
                              <Input
                                id={`answer-${index}`}
                                type="text"
                                placeholder="Zadaj svoje riešenie"
                                value={userAnswers[index] || ""}
                                onChange={(e) => {
                                  const newAnswers = [...userAnswers];
                                  newAnswers[index] = e.target.value;
                                  setUserAnswers(newAnswers);
                                }}
                                className="w-full"
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Input
                        type="text"
                        placeholder="Zadaj svoje riešenie"
                        value={userAnswer}
                        onChange={(e) => setUserAnswer(e.target.value)}
                        className="w-full mb-4"
                      />
                    )}

                    <div className="space-y-4 mt-4 mb-4">
                      <div className="flex flex-col sm:flex-row justify-between items-stretch sm:items-center space-y-2 sm:space-y-0">
                        <div className="flex flex-col sm:flex-row sm:space-x-2 space-y-2 sm:space-y-0">
                          <Button
                            onClick={checkSolution}
                            className="w-full sm:w-auto"
                          >
                            Overiť riešenie
                          </Button>
                          {selectedProblem.hint &&
                            selectedProblem.hint.trim() !== "" && (
                              <Button
                                onClick={() => setShowHint(!showHint)}
                                variant="outline"
                                className="w-full sm:w-auto"
                              >
                                {showHint
                                  ? "Skryť nápovedu"
                                  : "Zobraziť nápovedu"}
                              </Button>
                            )}
                        </div>
                        <div className="w-full sm:w-auto">
                          <VideoRequest problemId={selectedProblem.id} />
                        </div>
                      </div>
                    </div>
                    <AnimatePresence>
                      {feedback && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.9 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.9 }}
                          className={`flex items-center p-4 mb-4 rounded ${
                            feedback.correct
                              ? "bg-green-100 text-green-800"
                              : "bg-red-100 text-red-800"
                          }`}
                        >
                          {feedback.correct ? (
                            <CheckCircle className="mr-2" />
                          ) : (
                            <XCircle className="mr-2" />
                          )}
                          {feedback.message}
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <AnimatePresence>
                      {currentVariants && currentVariants.length > 0 && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          className="mt-6 bg-gray-50 border border-gray-200 rounded-lg mb-4 p-4 overflow-hidden"
                        >
                          <h3 className="text-lg font-semibold mb-2">
                            Obdobné príklady:
                          </h3>
                          <div className="space-y-2 pb-4">
                            <AnimatePresence>
                              {isVariantsLoading ? (
                                <>
                                  <VariantSkeleton />
                                  <VariantSkeleton />
                                  <VariantSkeleton />
                                </>
                              ) : currentVariants &&
                                currentVariants.length > 0 ? (
                                currentVariants.map((variant) => (
                                  <motion.div
                                    key={variant.id}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -20 }}
                                    transition={{ duration: 0.2 }}
                                  >
                                    <Button
                                      onClick={() => startChallenge(variant)}
                                      variant="secondary"
                                      className="w-full text-left bg-inherit justify-start hover:bg-gray-200"
                                    >
                                      <MixedContent
                                        content={variant.question}
                                      />
                                    </Button>
                                  </motion.div>
                                ))
                              ) : (
                                <p className="text-gray-500">
                                  Žiadne obdobné príklady
                                </p>
                              )}
                            </AnimatePresence>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>

                    <AnimatePresence>
                      {showHint && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4"
                        >
                          <h3 className="font-bold text-yellow-800 mb-2 ">
                            Nápoveda:
                          </h3>
                          <p className="text-yellow-700">
                            {selectedProblem.hint}
                          </p>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </Card>
                </div>
                <div className="w-full order-3 lg:hidden mt-4">
                  <RelatedProblems
                    problems={getRelatedProblems(selectedProblem)}
                    onProblemClick={(problem) => {
                      setSelectedProblem(problem);
                      navigate(`/problem=${problem.id}`);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {filteredProblems.length > 0
                ? filteredProblems.map((problem) => (
                    <MathProblemCard
                      key={`problem-${problem.id}`}
                      problem={problem}
                      onSave={handleSaveProblem}
                      isSaved={savedProblems.includes(problem.id)}
                      onClick={() => startChallenge(problem)}
                      canSave={canSaveProblems()}
                      isAdminUser={isAdminUser}
                      onEdit={handleEditProblem}
                      onDelete={handleDeleteProblem}
                      isSelected={
                        selectedProblems.includes(problem.id) || allSelected
                      }
                      onSelect={handleProblemSelect}
                      allTags={allTags}
                    />
                  ))
                : mathProblems.map((problem) => (
                    <MathProblemCard
                      key={`problem-${problem.id}`}
                      problem={problem}
                      onSave={handleSaveProblem}
                      isSaved={savedProblems.includes(problem.id)}
                      onClick={() => startChallenge(problem)}
                      canSave={canSaveProblems()}
                      isAdminUser={isAdminUser}
                      onEdit={handleEditProblem}
                      onDelete={handleDeleteProblem}
                      isSelected={
                        selectedProblems.includes(problem.id) || allSelected
                      }
                      onSelect={handleProblemSelect}
                      allTags={allTags}
                    />
                  ))}
            </Masonry>
          )}

          {isEditDialogOpen && problemToEdit && (
            <EditMathProblem
              problem={problemToEdit}
              onClose={() => setIsEditDialogOpen(false)}
              onProblemUpdated={handleProblemUpdated}
              session={session}
              open={isEditDialogOpen}
            />
          )}

          <AlertDialog
            open={showDeleteConfirmation}
            onOpenChange={setShowDeleteConfirmation}
          >
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Ste si istý, že chcete vymazať tento príklad?
                </AlertDialogTitle>
                <AlertDialogDescription>
                  Táto akcia sa nedá vrátiť späť. Príklad bude natrvalo
                  odstránený z databázy. Všetky súvisiace žiadosti o video a
                  hodnotenia videa budú tiež odstránené.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Zrušiť</AlertDialogCancel>
                <AlertDialogAction onClick={confirmDeleteProblem}>
                  Vymazať
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </>
      )}

      <Dialog open={showAddProblem} onOpenChange={setShowAddProblem}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>Pridať nový matematický príklad</DialogTitle>
          </DialogHeader>
          <AddMathProblem
            onClose={() => setShowAddProblem(false)}
            onProblemAdded={handleProblemAdded}
            session={session}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={showCalendar} onOpenChange={setShowCalendar}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>Kalendár Dostupnosti</DialogTitle>
          </DialogHeader>
          <LessonCalendar
            isAdminUser={isAdminUser}
            onClose={() => setShowCalendar(false)}
          />
        </DialogContent>
      </Dialog>
      {isPrinting && <PrintingLoader />}
      <Dialog open={isPrinting} onOpenChange={setIsPrinting}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Export do PDF</DialogTitle>
            <DialogDescription>
              Zadajte nadpis svojho súboru PDF. Ten sa zobrazí v hornej časti
              prvej strany.
            </DialogDescription>
          </DialogHeader>
          <Input
            value={pdfHeadline}
            onChange={(e) => setPdfHeadline(e.target.value)}
            placeholder="Zadaj nadpis"
          />
          <DialogFooter>
            <Button onClick={() => setIsPrinting(false)}>Zrušiť</Button>
            <Button onClick={handlePrintPDF}>Generuj PDF</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default App;
