import React from "react";
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import MixedContent from "./MixedContent";
import { difficultyStyles } from "../lib/mathProblemUtils";

const RelatedProblems = ({ problems, onProblemClick }) => {
  if (!problems || problems.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Súvisiace príklady</h3>
      {problems.map((problem) => (
        <Card
          key={problem.id}
          className={`${difficultyStyles[problem.difficulty].bgColor} ${
            difficultyStyles[problem.difficulty].borderColor
          } border cursor-pointer transition-all duration-300 hover:shadow-lg`}
          onClick={() => onProblemClick(problem)}
        >
          <CardContent className="p-4">
            <div className="mb-2">
              <span
                className={`text-sm font-medium px-2 py-1 rounded-full ${
                  difficultyStyles[problem.difficulty].textColor
                } bg-white bg-opacity-50`}
              >
                {problem.difficulty}
              </span>
            </div>
            <div
              className={`${
                difficultyStyles[problem.difficulty].textColor
              } break-words mb-2`}
            >
              <MixedContent content={problem.question} />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default RelatedProblems;
