import React, { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Textarea } from "./ui/textarea";
import MixedContent from "./MixedContent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { uploadImage } from "../lib/imageUpload";
import { transformYouTubeUrl } from "../lib/utils";
import SubQuestionEditor from "./SubQuestionEditor";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { difficultyStyles } from "../lib/mathProblemUtils";
import { X, PlusCircle, ChevronDown, ChevronUp } from "lucide-react";
import { toast } from "react-hot-toast";
import SearchableTagSelect from "./SearchableTagSelect";
import { Badge } from "./ui/badge";
import { Checkbox } from "./ui/checkbox";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import VariantForm from "./VariantForm";

const AddMathProblem = ({ onClose, onProblemAdded, session }) => {
  const [problem, setProblem] = useState({
    question: "",
    answer: [""],
    difficulty: "nezaradené",
    hint: "",
    image_url: "",
    video_tutorial_url: "",
    image_size: "medium",
    image_alignment: "center",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showVariantForm, setShowVariantForm] = useState(false);
  const [editingVariant, setEditingVariant] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTagSelect, setShowTagSelect] = useState(false);
  const [variants, setVariants] = useState([]);
  const [isMultipleChoice, setIsMultipleChoice] = useState(false);
  const [options, setOptions] = useState([]);
  const [correctOption, setCorrectOption] = useState(null);
  const [subQuestions, setSubQuestions] = useState([]);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    try {
      const { data, error } = await supabase
        .from("tags")
        .select("*")
        .order("name");

      if (error) throw error;
      setAllTags(data);
    } catch (error) {
      console.error("Error fetching tags:", error);
      toast.error("Failed to fetch tags");
    }
  };

  const handleInputChange = (name, value) => {
    if (name === "video_tutorial_url") {
      value = transformYouTubeUrl(value);
    }
    setProblem((prev) => ({ ...prev, [name]: value }));
  };

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...problem.answer];
    newAnswers[index] = value;
    setProblem((prev) => ({ ...prev, answer: newAnswers }));
  };

  const addAnswer = () => {
    setProblem((prev) => ({ ...prev, answer: [...prev.answer, ""] }));
  };

  const removeAnswer = (index) => {
    setProblem((prev) => ({
      ...prev,
      answer: prev.answer.filter((_, i) => i !== index),
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleQuillChange = (content) => {
    handleInputChange("question", content);
  };

  const handleAddVariant = () => {
    setEditingVariant(null);
    setShowVariantForm(true);
  };

  const handleSaveVariant = (variantData) => {
    if (editingVariant !== null) {
      setVariants((prev) =>
        prev.map((v, i) => (i === editingVariant.index ? variantData : v))
      );
      toast.success("Variant bol úspešne upravený");
    } else {
      setVariants((prev) => [...prev, variantData]);
      toast.success("Variant bol úspešne pridaný");
    }
    setShowVariantForm(false);
    setEditingVariant(null);
  };

  const handleAddOption = () => {
    setOptions([...options, ""]);
  };

  const handleOptionChange = (index, content) => {
    const newOptions = [...options];
    newOptions[index] = content;
    setOptions(newOptions);
  };

  const handleCorrectOptionChange = (index) => {
    setCorrectOption(index);
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsUploading(true);
    try {
      let imageUrl = null;
      if (imageFile) {
        imageUrl = await uploadImage(imageFile, session);
      }

      const problemData = {
        ...problem,
        image_url: imageUrl || problem.image_url,
        options: isMultipleChoice ? options : null,
        answer: isMultipleChoice ? [options[correctOption]] : problem.answer,
        sub_questions: subQuestions.length > 0 ? subQuestions : null, // Add this line
      };

      const { data: mainProblemData, error: mainProblemError } = await supabase
        .from("math_problems")
        .insert([problemData])
        .select();

      if (mainProblemError) throw mainProblemError;

      if (mainProblemData && mainProblemData.length > 0) {
        const mainProblem = mainProblemData[0];

        if (selectedTags.length > 0) {
          const tagAssociations = selectedTags.map((tag) => ({
            problem_id: mainProblem.id,
            tag_id: tag.id,
          }));

          const { error: tagAssociationError } = await supabase
            .from("math_problem_tags")
            .insert(tagAssociations);

          if (tagAssociationError) throw tagAssociationError;
        }

        if (variants.length > 0) {
          const variantInserts = variants.map((variant) => ({
            ...variant,
            main_problem_id: mainProblem.id,
          }));

          const { data: variantData, error: variantError } = await supabase
            .from("math_problems")
            .insert(variantInserts)
            .select();

          if (variantError) throw variantError;

          mainProblem.variants = variantData;
        } else {
          mainProblem.variants = [];
        }

        onProblemAdded(mainProblem);
        onClose();
        toast.success("Príklad bol úspešne pridaný");
      } else {
        throw new Error("No data returned from insert operation");
      }
    } catch (error) {
      console.error("Error adding problem:", error.message);
      toast.error("Chyba pri pridávaní príkladu: " + error.message);
    } finally {
      setIsSubmitting(false);
      setIsUploading(false);
    }
  };

  return (
    <div className="space-y-6">
      <form onSubmit={handleSubmit} className="space-y-6">
        <Tabs defaultValue="basic" className="w-full">
          <TabsList className="grid w-full grid-cols-4">
            <TabsTrigger value="basic">Základné info</TabsTrigger>
            <TabsTrigger value="details">Detaily</TabsTrigger>
            <TabsTrigger value="media">Médiá</TabsTrigger>
            <TabsTrigger value="variants">Varianty</TabsTrigger>
          </TabsList>
          <TabsContent value="basic" className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="question">Otázka</Label>
              <ReactQuill
                theme="snow"
                value={problem.question}
                onChange={handleQuillChange}
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    ["clean"],
                    ["formula"],
                  ],
                }}
              />

              <SubQuestionEditor
                subQuestions={subQuestions}
                setSubQuestions={setSubQuestions}
              />

              <div className="mt-2">
                <Label>Náhľad:</Label>
                <MixedContent content={problem.question} />
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="isMultipleChoice"
                  checked={isMultipleChoice}
                  onCheckedChange={setIsMultipleChoice}
                />
                <Label htmlFor="isMultipleChoice">
                  Otázka s možnosťou výberu
                </Label>
              </div>

              {isMultipleChoice ? (
                <div className="space-y-2">
                  {options.map((option, index) => (
                    <div
                      key={index}
                      className="flex items-center space-x-2 mb-4"
                    >
                      <Checkbox
                        checked={index === correctOption}
                        onCheckedChange={() => handleCorrectOptionChange(index)}
                      />
                      <div className="flex-grow">
                        <ReactQuill
                          value={option}
                          onChange={(content) =>
                            handleOptionChange(index, content)
                          }
                          modules={{
                            toolbar: [
                              ["bold", "italic", "underline"],
                              ["formula"],
                            ],
                          }}
                        />
                        <div className="mt-2 p-2 bg-gray-100 rounded">
                          <Label className="text-sm text-gray-600">
                            Náhľad:
                          </Label>
                          <MixedContent content={option} />
                        </div>
                      </div>
                      <Button
                        type="button"
                        variant="outline"
                        onClick={() => handleRemoveOption(index)}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                  <Button
                    type="button"
                    onClick={handleAddOption}
                    variant="outline"
                  >
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Pridať možnosť
                  </Button>
                </div>
              ) : (
                <div className="space-y-2">
                  <Label htmlFor="answer">Odpovede</Label>
                  {problem.answer.map((ans, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <Input
                        value={ans}
                        onChange={(e) =>
                          handleAnswerChange(index, e.target.value)
                        }
                        placeholder={`Odpoveď ${index + 1}`}
                        required
                      />
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        onClick={() => removeAnswer(index)}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}
                  <Button type="button" onClick={addAnswer} variant="outline">
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Pridať ďalšiu odpoveď
                  </Button>
                </div>
              )}
            </div>
          </TabsContent>

          <TabsContent value="details" className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="difficulty">Obtiažnosť</Label>
                <Select
                  value={problem.difficulty || "nezaradené"}
                  onValueChange={(value) =>
                    handleInputChange("difficulty", value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Vyberte obtiažnosť" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="nezaradené">Nezaradené</SelectItem>
                    <SelectItem value="ľahké">Ľahké</SelectItem>
                    <SelectItem value="stredné">Stredné</SelectItem>
                    <SelectItem value="ťažké">Ťažké</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="hint">Nápoveda (voliteľné)</Label>
              <Textarea
                id="hint"
                value={problem.hint}
                onChange={(e) => handleInputChange("hint", e.target.value)}
                placeholder="Zadajte nápovedu"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="tags">Tagy</Label>
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowTagSelect(!showTagSelect)}
                className="w-full justify-between"
              >
                <span>Vybrať tagy ({selectedTags.length} vybrané)</span>
                {showTagSelect ? (
                  <ChevronUp className="h-4 w-4" />
                ) : (
                  <ChevronDown className="h-4 w-4" />
                )}
              </Button>
              {showTagSelect && (
                <SearchableTagSelect
                  allTags={allTags}
                  selectedTags={selectedTags}
                  onChange={setSelectedTags}
                  onTagToggle={(tagId) => {
                    setSelectedTags((prev) =>
                      prev.includes(tagId)
                        ? prev.filter((id) => id !== tagId)
                        : [...prev, tagId]
                    );
                  }}
                />
              )}
              {selectedTags.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {selectedTags.map((tagId) => {
                    const tag = allTags.find((t) => t.id === tagId);
                    return tag ? (
                      <Badge
                        key={tagId}
                        variant="secondary"
                        className="text-sm"
                      >
                        {tag.name}
                        <button
                          type="button"
                          onClick={() =>
                            setSelectedTags(
                              selectedTags.filter((id) => id !== tagId)
                            )
                          }
                          className="ml-1 text-gray-500 hover:text-gray-700"
                        >
                          ×
                        </button>
                      </Badge>
                    ) : null;
                  })}
                </div>
              )}
            </div>
          </TabsContent>

          <TabsContent value="media" className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="image">Obrázok (voliteľné)</Label>
              <div className="flex items-center space-x-2">
                <Input
                  id="image"
                  type="file"
                  accept="image/jpeg,image/png,image/gif"
                  onChange={handleImageChange}
                  className="flex-grow"
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="w-16 h-16 object-cover rounded"
                  />
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="image_size">Veľkosť obrázku</Label>
                <Select
                  value={problem.image_size}
                  onValueChange={(value) =>
                    handleInputChange("image_size", value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Vyberte veľkosť" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="small">Malý</SelectItem>
                    <SelectItem value="medium">Stredný</SelectItem>
                    <SelectItem value="large">Veľký</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label htmlFor="image_alignment">Zarovnanie obrázku</Label>
                <Select
                  value={problem.image_alignment}
                  onValueChange={(value) =>
                    handleInputChange("image_alignment", value)
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Vyberte zarovnanie" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="left">Vľavo</SelectItem>
                    <SelectItem value="center">Na stred</SelectItem>
                    <SelectItem value="right">Vpravo</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="video_tutorial_url">
                Video tutoriál URL (voliteľné)
              </Label>
              <Input
                id="video_tutorial_url"
                type="url"
                value={problem.video_tutorial_url}
                onChange={(e) =>
                  handleInputChange("video_tutorial_url", e.target.value)
                }
                placeholder="https://www.youtube.com/watch?v=..."
              />
            </div>
          </TabsContent>

          <TabsContent value="variants" className="space-y-4">
            <div className="space-y-2">
              <Label>Obdobné príklady</Label>
              <div className="space-y-2">
                {variants.map((variant, index) => (
                  <div
                    key={index}
                    className="flex items-center space-x-2 bg-gray-100 p-2 rounded"
                  >
                    <div className="flex-grow">
                      <p className="font-medium">
                        <MixedContent content={variant.question} />
                      </p>
                      <p className="text-sm text-gray-600">
                        Odpoveď:{" "}
                        {Array.isArray(variant.answer) ? (
                          variant.answer.map((ans, i) => (
                            <span key={i} className="mr-2">
                              <MixedContent content={ans} />
                            </span>
                          ))
                        ) : (
                          <MixedContent content={variant.answer} />
                        )}
                      </p>
                    </div>
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        setEditingVariant({ ...variant, index });
                        setShowVariantForm(true);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      type="button"
                      variant="destructive"
                      size="sm"
                      onClick={() => {
                        setVariants(variants.filter((_, i) => i !== index));
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                type="button"
                onClick={handleAddVariant}
                variant="outline"
              >
                <PlusCircle className="h-4 w-4 mr-2" />
                Pridať obdobný príklad
              </Button>
            </div>
          </TabsContent>
        </Tabs>
        <div className="flex justify-end space-x-2 pt-4">
          <Button
            type="button"
            variant="outline"
            onClick={onClose}
            disabled={isSubmitting || isUploading}
          >
            Zrušiť
          </Button>
          <Button type="submit" disabled={isSubmitting || isUploading}>
            {isSubmitting || isUploading ? "Pridávam..." : "Pridať príklad"}
          </Button>
        </div>
      </form>

      {showVariantForm && (
        <VariantForm
          variant={editingVariant}
          onSave={handleSaveVariant}
          onClose={() => {
            setShowVariantForm(false);
            setEditingVariant(null);
          }}
          session={session}
        />
      )}
    </div>
  );
};

export default AddMathProblem;
