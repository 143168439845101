import React from "react";
import { Card, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import { Bookmark, Edit, Trash2 } from "lucide-react";
import { Checkbox } from "./ui/checkbox";
import { difficultyStyles } from "../lib/mathProblemUtils";
import { useNavigate } from "react-router-dom";

import MixedContent from "./MixedContent";

const MathProblemCard = ({
  problem,
  onSave,
  isSaved,
  onClick,
  canSave,
  isAdminUser,
  onEdit,
  onDelete,
  isSelected,
  onSelect,
  allTags,
}) => {
  const getDifficultyStyle = (difficulty) => {
    return difficultyStyles[difficulty] || difficultyStyles.stredné;
  };
  const navigate = useNavigate();

  const getTagName = (tagId) => {
    const tag = allTags.find((t) => t.id === tagId);
    return tag ? tag.name : "Unknown Tag";
  };

  const difficultyStyle = getDifficultyStyle(problem.difficulty);

  const getImageSizeClass = (size) => {
    switch (size) {
      case "small":
        return "w-1/3";
      case "large":
        return "w-full";
      default:
        return "w-2/3";
    }
  };

  const getImageAlignmentClass = (alignment) => {
    switch (alignment) {
      case "left":
        return "float-left mr-4";
      case "right":
        return "float-right ml-4";
      default:
        return "mx-auto";
    }
  };

  return (
    <Card
      className={`${difficultyStyle.bgColor} ${difficultyStyle.borderColor} border cursor-pointer transition-all duration-300 hover:shadow-lg`}
      onClick={(e) => {
        e.preventDefault();
        console.log("Card clicked:", problem);
        navigate("/problem=" + problem.id);

        onClick(problem);
      }}
    >
      <CardContent className="p-4 flex flex-col">
        <div className="mb-2 flex items-center justify-between">
          <span
            className={`text-sm font-medium px-2 py-1 rounded-full ${difficultyStyle.textColor} bg-white bg-opacity-50`}
          >
            {problem.difficulty}
          </span>
        </div>

        <div className={`${difficultyStyle.textColor} break-words mb-2`}>
          <div className="math-content">
            <MixedContent content={problem.question} />
          </div>
        </div>

        {problem.image_url && (
          <div
            className={`mb-2 w-full ${getImageSizeClass(
              problem.image_size
            )} ${getImageAlignmentClass(problem.image_alignment)}`}
          >
            <img
              src={problem.image_url}
              alt="Problem illustration"
              className="w-full h-auto rounded-lg"
            />
          </div>
        )}

        {Array.isArray(problem.tags) && problem.tags.length > 0 && (
          <div className="flex flex-wrap gap-1 mt-2">
            {problem.tags.map((tagId) => (
              <span
                key={tagId}
                className="text-xs bg-white bg-opacity-50 rounded-full px-2 py-1"
              >
                {getTagName(tagId)}
              </span>
            ))}
          </div>
        )}

        <div className="flex justify-between items-center mt-4">
          {canSave && (
            <Button
              variant="ghost"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onSave(problem.id);
              }}
              className={isSaved ? "text-yellow-600" : "text-gray-600"}
            >
              <Bookmark className="h-5 w-5" />
            </Button>
          )}
          {isAdminUser && (
            <div className="flex space-x-2 items-center">
              <Checkbox
                checked={isSelected}
                onCheckedChange={(checked) => {
                  onSelect(problem.id, checked);
                }}
                onClick={(e) => e.stopPropagation()}
              />
              <Button
                variant="ghost"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(problem);
                }}
              >
                <Edit className="h-5 w-5" />
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(problem.id);
                }}
              >
                <Trash2 className="h-5 w-5" />
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default MathProblemCard;
