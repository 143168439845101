import React, { useState, useRef, useEffect } from "react";
import { Command } from "cmdk";
import { Check } from "lucide-react";

const SearchableTagSelect = ({
  allTags,
  selectedTags,
  onChange,
  onTagToggle,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);

  const filteredTags = allTags.filter((tag) =>
    tag.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleTag = (tag) => {
    onTagToggle(tag.id);
    setSearchTerm("");
    // Focus on the input after toggling a tag
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Focus on the input when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Command className="rounded-lg border shadow-md">
      <Command.Input
        ref={inputRef}
        placeholder="Hľadať tagy..."
        value={searchTerm}
        onValueChange={setSearchTerm}
        className="w-full p-2"
      />
      <Command.List className="max-h-64 overflow-y-auto">
        {filteredTags.map((tag) => (
          <Command.Item
            key={tag.id}
            onSelect={() => toggleTag(tag)}
            className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
          >
            <div
              className={`mr-2 ${
                selectedTags.includes(tag.id)
                  ? "text-blue-500"
                  : "text-transparent"
              }`}
            >
              <Check size={16} />
            </div>
            {tag.name}
          </Command.Item>
        ))}
      </Command.List>
    </Command>
  );
};

export default SearchableTagSelect;
