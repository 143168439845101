import React, { useState, useEffect, useCallback } from "react";
import { Calendar, dateFnsLocalizer, Views } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import skLocale from "date-fns/locale/sk";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button } from "./ui/button";
import { supabase } from "./supabaseClient";
import { toast } from "react-hot-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";
import { ChevronLeft, ChevronRight } from "lucide-react";

const locales = {
  sk: skLocale,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const messages = {
  allDay: "Celý deň",
  previous: "Predchádzajúci",
  next: "Ďalší",
  today: "Dnes",
  month: "Mesiac",
  week: "Týždeň",
  day: "Deň",
  agenda: "Agenda",
  date: "Dátum",
  time: "Čas",
  event: "Udalosť",
};

const CustomToolbar = ({ date, view, onNavigate, onView }) => (
  <div className="flex justify-between items-center mb-4">
    <div>
      <Button variant="outline" onClick={() => onNavigate("TODAY")}>
        Dnes
      </Button>
    </div>
    <div className="flex items-center space-x-2">
      <Button variant="outline" onClick={() => onNavigate("PREV")}>
        <ChevronLeft className="h-4 w-4" />
      </Button>
      <span className="text-lg font-semibold">
        {format(date, "MMMM yyyy", { locale: skLocale })}
      </span>
      <Button variant="outline" onClick={() => onNavigate("NEXT")}>
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
    <div className="space-x-2">
      <Button
        variant={view === Views.MONTH ? "default" : "outline"}
        onClick={() => onView(Views.MONTH)}
      >
        Mesiac
      </Button>
      <Button
        variant={view === Views.WEEK ? "default" : "outline"}
        onClick={() => onView(Views.WEEK)}
      >
        Týždeň
      </Button>
      <Button
        variant={view === Views.DAY ? "default" : "outline"}
        onClick={() => onView(Views.DAY)}
      >
        Deň
      </Button>
    </div>
  </div>
);

const LessonCalendar = ({ isAdminUser, onClose }) => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [view, setView] = useState(Views.WEEK);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("available_slots")
        .select("*");

      if (error) throw error;

      const formattedEvents = data.map((slot) => ({
        id: slot.id,
        title: "Dostupný",
        start: new Date(slot.start_time),
        end: new Date(slot.end_time),
      }));

      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
      toast.error(`Chyba pri načítaní termínov: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectSlot = (slotInfo) => {
    if (isAdminUser) {
      addNewSlot(slotInfo);
    }
  };

  const addNewSlot = async (slotInfo) => {
    setIsLoading(true);
    try {
      const startTime = new Date(slotInfo.start);
      const endTime = new Date(slotInfo.end);

      startTime.setMinutes(Math.round(startTime.getMinutes() / 30) * 30);

      if (endTime - startTime < 30 * 60 * 1000) {
        endTime.setTime(startTime.getTime() + 30 * 60 * 1000);
      }

      const { data, error } = await supabase
        .from("available_slots")
        .insert([
          {
            start_time: startTime.toISOString(),
            end_time: endTime.toISOString(),
          },
        ])
        .select();

      if (error) throw error;

      if (data && data.length > 0) {
        const newEvent = {
          id: data[0].id,
          title: "Dostupný",
          start: new Date(data[0].start_time),
          end: new Date(data[0].end_time),
        };

        setEvents((prevEvents) => [...prevEvents, newEvent]);
        toast.success("Termín bol úspešne pridaný");
      } else {
        console.warn("Slot added but no data returned");
        await fetchEvents();
      }
    } catch (error) {
      console.error("Error adding event:", error);
      toast.error(`Chyba pri pridávaní termínu: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectEvent = (event) => {
    if (isAdminUser) {
      setSelectedEvent(event);
      setShowDeleteDialog(true);
    } else {
      handleBooking();
    }
  };

  const handleDeleteSlot = async () => {
    if (selectedEvent && isAdminUser) {
      setIsLoading(true);
      try {
        const { error } = await supabase
          .from("available_slots")
          .delete()
          .eq("id", selectedEvent.id);

        if (error) throw error;

        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.id !== selectedEvent.id)
        );
        toast.success("Termín bol úspešne vymazaný");
      } catch (error) {
        console.error("Error deleting event:", error);
        toast.error(`Chyba pri mazaní termínu: ${error.message}`);
      } finally {
        setIsLoading(false);
        setShowDeleteDialog(false);
        setSelectedEvent(null);
      }
    }
  };

  const handleBooking = () => {
    window.location.href =
      "https://www.doucma.sk/11032-doucovanie-matika-od-patrika-aj-fyzika";
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    return {
      style: {
        backgroundColor: "#10B981",
        borderRadius: "0px",
        opacity: 0.8,
        color: "white",
        border: "0px",
        display: "block",
      },
    };
  };

  const handleNavigate = useCallback((newDate) => {
    setDate(newDate);
  }, []);

  return (
    <div className="h-[600px] flex flex-col">
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "100%" }}
          selectable={isAdminUser}
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          step={30}
          timeslots={2}
          views={[Views.MONTH, Views.WEEK, Views.DAY]}
          view={view}
          onView={setView}
          date={date}
          onNavigate={handleNavigate}
          min={new Date(0, 0, 0, 9, 0, 0)}
          max={new Date(0, 0, 0, 20, 0, 0)}
          messages={messages}
          culture="sk"
          eventPropGetter={eventStyleGetter}
          components={{
            toolbar: CustomToolbar,
          }}
        />
      )}
      <AlertDialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Vymazať termín</AlertDialogTitle>
            <AlertDialogDescription>
              Ste si istý, že chcete vymazať tento termín? Táto akcia sa nedá
              vrátiť späť.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Zrušiť</AlertDialogCancel>
            <AlertDialogAction onClick={handleDeleteSlot}>
              Vymazať
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default LessonCalendar;
